<template>
  <div class="home" style="position: relative">
    <!-- <div v-if="fontAvailable == false" style="background: #ffff00">
      <p>Um die Ankündigung korrekt zu erstellen bitte die Schriftart Merriweather und Merriweather Sans installieren</p>
      <ul>
        <li><a href="https://fonts.google.com/download?family=Merriweather">Download Merriweather</a></li>
        <li><a href="https://fonts.google.com/download?family=Merriweather%20Sans">Download Merriweather Sans</a></li>
      </ul>
      
    </div> -->
    <!-- <img alt="Vue logo" style="max-width: 300px" src="../assets/bock_auf_hell.svg"> -->
    <div v-if="createSingle || createTagVorschau || createTagErgebnisse" class="overlay">
      <i @click="() => {createSingle = false; createTagVorschau = false; createTagErgebnisse= false}" class="fa fa-times-circle close-icon" aria-hidden="true"></i>
      <!-- <button >Schließe Vorschau</button> -->
      <div>
        <single-game v-if="createSingle == true" :date="date" :game="gameSelect" :winscale="getScale"></single-game>
        <tag-vorschau v-if="createTagVorschau == true" :data="data" :winscale="getScale"></tag-vorschau>
        <tag-ergebnisse v-if="createTagErgebnisse == true" :data="data" :winscale="getScale"></tag-ergebnisse>
      </div>
    </div>
    <div id="teamselector">
      <p>Team: <team-selector v-on:selected-team='setTeam' :inTeam='team'></team-selector></p>
    </div>
    <div>
    <div class="day" :id="isCurrent(gameDay[0])" v-for="(gameDay, index) in getGames" :key="'gameDay' + index"><b>{{gameDay[0]}}</b><span v-if="team=='all'" @click="createSpieltag(gameDay)" class="icon-button">Spieltag</span><!-- <button v-if="team=='all'" @click="()=>{ data=gameDay; createTagVorschau=true; createSingle=false; createTagErgebnisse=false }">Spieltag Ankündigung</button><button v-if="team=='all'" @click="()=>{ data=gameDay; createTagVorschau=false; createSingle=false; createTagErgebnisse=true }">Spieltag Ergebnisse</button> -->
        <div v-for="(game, i) in gameDay[1]" :key="'game' + i">
          <p @click="()=>{ date=gameDay[0]; gameSelect=game; createSingle=true; createTagVorschau=false; createTagErgebnisse=false;}" class="game" v-if="game[1].includes('Giebelstadt')">{{game[0] + " Uhr, " + game[3] + " vs " + game[5]}} <span v-if="ergebnis(game[6])"> (<span v-html="underline(game[6], true)"></span>)</span><!-- <i class="fa fa-cog icon-button" aria-hidden="true"></i> --></p>
          <p @click="()=>{ date=gameDay[0]; gameSelect=game; createSingle=true; createTagVorschau=false; createTagErgebnisse= false;}" class="auswaerts game" v-else>{{game[0] + " Uhr, " + game[3] + " vs " + game[4]}} <span v-if="ergebnis(game[6])"> (<span v-html="underline(game[6], false)"></span>)</span><!-- <i class="fa fa-cog icon-button" aria-hidden="true" ></i>--></p>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import TeamSelector from '../components/TeamSelector.vue';
import SingleGame from '../components/SingleGameSVG.vue';
import TagVorschau from '../components/TagVorschau.vue';
import TagErgebnisse from '../components/TagErgebnisse.vue';
import moment from 'moment';

import {mapActions, mapGetters} from 'vuex';

let min = Number.NEGATIVE_INFINITY;

export default {
  name: 'Home',
  components: {
    //HelloWorld,
    'single-game': SingleGame,
    'tag-vorschau': TagVorschau,
    'tag-ergebnisse': TagErgebnisse,
    'team-selector': TeamSelector,
  },
  data() {
    return {
      team: 'all',
      date: '',
      createSingle: false,
      createTagVorschau: false,
      createTagErgebnisse: false,
      //fontAvailable: false,
      gameSelect: [],
      fitScale: 1,
    }
  },
  methods: {
    ...mapActions(['fetchGames']),
    setTeam(val){
      console.log("setTeam: " + val);
      let min = Number.NEGATIVE_INFINITY;
      if(val){
        this.team = val; 
        //console.log(val);
        this.fetchGames(this.team);

        setTimeout(() => {document.getElementById('current').scrollIntoView({block: "end"});}, 100);

      } else {
        this.fetchGames(null);
        setTimeout(() => {document.getElementById('current').scrollIntoView({block: "end"});}, 100);

      }
      /*let old = document.getElementById("SingleGame");      
      if(old){
        old.parentNode.removeChild(old);
      }*/
    },
    underline(ergebnis, home){
      let parts = ergebnis.split(":");
      if(home){
        //return '<span style="text-decoration: underline; text-underline-offset: 5px;">' + parts[0].trim() + '</span> : ' + parts[1].trim();
        return '<span style="font-weight: bold;">' + parts[0].trim() + '</span> : ' + parts[1].trim();
      } else {
        //return parts[0].trim() + ' : ' + '<span style="text-decoration: underline; text-underline-offset: 5px;">' + parts[1].trim() + "</span>";
        return parts[0].trim() + ' : ' + '<span style="font-weight: bold;">' + parts[1].trim() + "</span>";
      }
    },
    ergebnis(erg){
      if(erg.trim()[0] == "0" && erg.trim().slice(-1) == "0"){
        return false;
      } else {
        return true;
      }
    },
    createSpieltag(gameDay){

      let momentDateA = moment(gameDay[0].slice(3), 'DD-MM-YYYY');
      let momentNow = moment();

      this.data=gameDay;
      
      this.createSingle=false; 
      

      if(momentNow - momentDateA < 0){
        this.createTagVorschau=true; 
        this.createTagErgebnisse=false;
      } else {
        this.createTagVorschau=false; 
        this.createTagErgebnisse=true;
      }
    },
    isCurrent(date){
      let diff = moment() - moment(date.slice(3), 'DD-MM-YYYY');

      //console.log(diff);

      if(diff < 24*60*60*1000 && diff > min){
        min = diff;
        return "current";
      }
      return false;
    }

  },
  computed: {
     ...mapGetters(['getGames']),
     getScale(){

      //if(window.innerWidth/1080.0 > window.innerHeight/1920);
      let marginTop = 20;

      let horizontal = (window.innerHeight-marginTop)/1920;
      let vertical = window.innerWidth/1080;
      return Math.min(horizontal, vertical);
     }
  },
  created(){
    this.fetchGames(null);


    setTimeout(() => {document.getElementById('current').scrollIntoView({block: "end"});}, 1000);
    //document.getElementById('current').scrollIntoView();
    //let fontAvailable;
    /*document.fonts.ready.then(() => {
      console.log(document.fonts);
        this.fontAvailable = document.fonts.check("12px Merriweather");
        console.log("Merriweather awailable");
        if(this.fontAvailable){
          this.fontAvailable = document.fonts.check("12px Merriweather Sans");
          console.log("Merriweather Sans awailable");
        }
        //this.fontAvailable = false;
    });*/

    //:id="isCurrent(gameDay[0])"
  },
}
</script>
<style scoped>
  #teamselector{
    position: fixed;
    top: 0px;
    padding: 10px;
    background: rgb(0 0 0 / 80%);
    width: 100%;
  }

  #teamselector p{
    z-index: 1;
    padding: 3px;
    margin: 0px;
    color: #fff;
    font-weight: 700;
    font-size: 22px;
    text-transform: uppercase;
    letter-spacing: 0.05em;
    /* background: #ffffff; */
  }
  #teamselector p select {
    font-size: 22px;
  }

  .home{
    margin-top: 35px;
  }
  #current {
    background: rgb(255 224 0 / 60%);
  }

  .overlay {
    z-index: 10;
    position: fixed; 
    top: 0px;
    left: 0px;
    background: rgb(0 0 0 / 90%);
    width: 100%;
    height: 100%;
  }

  .auswaerts {
    color: rgb(116, 116, 116);
  }
  .game {
    cursor: grab;
    text-decoration: underline; 
    text-underline-offset: 5px; 
    text-decoration-thickness: 0.5px;
  }
  .bold {
    font-weight: bold; 
  }
  .underline {
    text-decoration: underline;
  }

  .day {
    padding: 20px 0px 20px 0px;
  }
  .day button {
    margin-left: 10px;
  }

  .close-icon{
    cursor: grab;
    color: #fff;
    font-size: 50px;
    position: fixed; 
    top: 20px;
    right: 20px;
    z-index: 20;
  }
  .icon-button {
    cursor: grab;
    margin-left: 10px;
    padding: 3px;
    /* font-size: 20px; */
    color: #2c3e50;
    background-color: rgb(243, 243, 243);
    border: 1px rgb(81, 81, 81) solid;
    border-radius: 5px;
  }
  .icon-button:hover {
    color: hsl(210, 29%, 7%);
    background-color: rgb(255 224 0);
  }

  .day:nth-child(2n){
    background-color: rgb(237, 237, 237);
  }
</style>
