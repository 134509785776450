<template>
    <select id="teams" @change="teamSelected" v-model="team">
            <option value="all"> -- alle -- </option>
            <option v-for="t in getTeams" :key='t' :value='t'>{{t}}</option>
    </select>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
    name: 'TeamSelector',
    props: {
            inTeam: {
                type: String,
            },
    },
    data(){
        return {
            team: '',
        }
    },
    methods: {
        ...mapActions(['fetchTeams']),
        teamSelected: function(event) {
            console.log(event.target.value);
            this.$emit('selected-team', event.target.value);//{date: event.target.value, value: this.getGames[event.target.value]});

            //console.log(this.getGames.val);
        }
    },
    computed: {
        ...mapGetters(['getTeams']),
    },
    created(){
        this.fetchTeams();
        this.team = this.inTeam;
    }
}
</script>

<style scoped>

</style>