<template>
    <!-- <div :style='"transform: scale(" + winscale + "," + winscale + "); transform-origin: top center; position:fixed; top: 20px; z-index: 20;"'> -->
    <div :style='"position: fixed;left: 50%;top: 50%;transform: translate(-50%, -50%), scale(" + winscale + "," + winscale + ");"'>
        <!-- <button @click="saveSVG">Save SVG</button> -->
        <i @click="savePNG" class="fa fa-arrow-circle-o-down download-icon" aria-hidden="true"></i>
        <!-- <button @click="savePNG">Save PNG</button> -->
    </div>
</template>

<script>
import { saveAs } from 'file-saver';
import { saveSvgAsPng } from 'save-svg-as-png'

export default {
    name: 'ExportSVG',
    props: {
            filename: {
                type: String,
                required: true
            },
            winscale:{
                type: Number,
                required: true,
            }
    },
    methods:{
        saveSVG: function(){
            this.exportSVG(document.getElementById('svg_canvas'));
        },
        savePNG: function(){
            saveSvgAsPng(document.getElementById("svg_canvas"), this.filename + ".png", {scale: 1});
        },
        exportSVG: function(svg) {
            // first create a clone of our svg node so we don't mess the original one
            var clone = svg.cloneNode(true);
            // parse the styles
            this.parseStyles(clone);

            // create a doctype
            var svgDocType = document.implementation.createDocumentType('svg', "-//W3C//DTD SVG 1.1//EN", "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd");
            // a fresh svg document
            var svgDoc = document.implementation.createDocument('http://www.w3.org/2000/svg', 'svg', svgDocType);
            // replace the documentElement with our clone 
            svgDoc.replaceChild(clone, svgDoc.documentElement);
            // get the data
            var svgData = (new XMLSerializer()).serializeToString(svgDoc);

            // now you've got your svg data, the following will depend on how you want to download it
            // here I'll use FileSaver.js (https://github.com/yrezgui/FileSaver.js)
            
            var blob = new Blob([svgData.replace(/></g, '>\n\r<')]);
            saveAs(blob, this.filename + '.svg');
        },

        parseStyles: function(svg) {
            var styleSheets = [];
            var i;
            // get the stylesheets of the document (ownerDocument in case svg is in <iframe> or <object>)
            var docStyles = svg.ownerDocument.styleSheets;

            // transform the live StyleSheetList to an array to avoid endless loop
            for (i = 0; i < docStyles.length; i++) {
                styleSheets.push(docStyles[i]);
            }

            if (!styleSheets.length) {
                return;
            }

            var defs = svg.querySelector('defs') || document.createElementNS('http://www.w3.org/2000/svg', 'defs');
            if (!defs.parentNode) {
                svg.insertBefore(defs, svg.firstElementChild);
            }
            svg.matches = svg.matches || svg.webkitMatchesSelector || svg.mozMatchesSelector || svg.msMatchesSelector || svg.oMatchesSelector;


            // iterate through all document's stylesheets
            for (i = 0; i < styleSheets.length; i++) {
                var currentStyle = styleSheets[i]

                var rules;
                try {
                rules = currentStyle.cssRules;
                } catch (e) {
                continue;
                }
                // create a new style element
                var style = document.createElement('style');
                // some stylesheets can't be accessed and will throw a security error
                var l = rules && rules.length;
                // iterate through each cssRules of this stylesheet
                for (var j = 0; j < l; j++) {
                // get the selector of this cssRules
                var selector = rules[j].selectorText;
                // probably an external stylesheet we can't access
                if (!selector) {
                    continue;
                }

                // is it our svg node or one of its children ?
                if ((svg.matches && svg.matches(selector)) || svg.querySelector(selector)) {

                    var cssText = rules[j].cssText;
                    // append it to our <style> node
                    style.innerHTML += cssText + '\n';
                }
            }
                // if we got some rules
                if (style.innerHTML) {
                // append the style node to the clone's defs
                defs.appendChild(style);
                }
            }
        }
    }
}
</script>

<style scoped>
  .download-icon{
    cursor: grab;
    color: rgb(255 224 0);
    font-size: 300px;
    transform: translate(-120px, -120px);
    text-shadow: 0px 0px 60px rgb(0, 0, 0);

    /* position: fixed; 
    top: 20px;
    z-index: 20; */
  }
</style>