import Vue from 'vue'
import Vuex from 'vuex'
import axios from "axios";
import moment from 'moment';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

Vue.use(BootstrapVue)
Vue.use(Vuex)

let url = "https://spvgg.manuelmichel.de";

export default new Vuex.Store({
  state: {
    games: {},
    teams: [],
    tabelle: {
      herren: {},
    }
  },
  getters: {
    getTeams: state => state.teams,
    getGames: state => state.games,
    getTabelle: state => (team) => state.tabelle[team],
  },
  mutations: {
    setTeams: (state, teams) => (state.teams = teams),
    //setGames: (state, games) => (state.games = games),
    setGames: (state, games) => {
      let sortedGames = Object.entries(games);
      sortedGames.sort(compare);
      console.log(sortedGames);
      // Add Array between
      for(let i = 0; i<sortedGames.length; i++){
        let temp = sortedGames[i][1];
        sortedGames[i][1] = [];
        sortedGames[i][1][0] = temp;
      }
      state.games = sortedGames;
    },
    setAllGames: (state, games) => {
      let sortedGames = Object.entries(games);
      sortedGames.sort(compare);
      console.log("sortedGames: ");
      console.log(sortedGames);
      state.games = sortedGames;
    },
    setTabelle: (state, tabelle) => {
      state.tabelle.herren = tabelle;
    }
  },
  actions: {
    async fetchGames({ commit }, team) {
      //console.log(url+`/?team=${team}`);
      if(team == "all"){
        const response = await axios.get(url+'/spielplan');
        commit('setAllGames', response.data);
      } else if(team){
        const response = await axios.get(url+`/?team=${team}&fromNow=true`);
        console.log(response.data);
        commit('setGames', response.data);
      } else {
        const response = await axios.get(url+'/spielplan');
        commit('setAllGames', response.data);
      }
      //const response = await axios.get(url+"/?team=BZOL%20M");
      //console.log(response.data);
      
    },
    async fetchTeams({ commit }) {
      const response = await axios.get(url+"/teams");
      //console.log("fetchTeams");
      //console.log(response.data);
      commit('setTeams', response.data);
    },
    async fetchTabellen({ commit }) {
      const response = await axios.get(url+"/tabelle?team=Herren");
      commit('setTabelle', response.data);
    }
  },
  modules: {
  }
})


function compare( a, b ) {

  let momentDateA = moment(a[0].slice(3), 'DD-MM-YYYY');
  let momentDateB = moment(b[0].slice(3), 'DD-MM-YYYY');
  return momentDateA - momentDateB;
}